<template>
	<div>
		<!-- 公共头部 -->
		<Header :title="courseChapterInfo.subject_title"></Header>
		
		<div class="dataUpload-box">
			<ul class="yiji">
				<li v-for="(item,index) in courseChapterInfo.chapter">
					<div class="yiji-box" :class="[ item.openStatus ? 'active' : '' ]" @click="item.openStatus = !item.openStatus">
						<img class="icon" src="@/assets/images/icon73.png" />
						<div class="title">{{item.title}} （{{item.keshi}}学时）</div>
					</div>
					<ul class="erji" :class="[ item.openStatus ? 'active' : '' ]">
						<template v-for="(items,idx) in item.lists" >
							<div @click="gotoVideo(items.id)" v-if="index == 0 && idx == 0" class="clearfix item">
								<span class="title fl">{{items.title}} （{{items.keshi}}学时）---- {{items.teacher}}</span>
								
								<div class="jindu fr">{{items.jindu}}</div>
								<div class="fr btn" v-if="items.status == '未学习'" :class="['btn2']">未学习</div>
								<div class="fr btn" v-if="items.status == '学习中'" :class="['btn1']">学习中</div>
								<div class="fr btn" v-if="items.status == '已学完'">已学完</div>
							</div>
							<div @click="gotoVideo(items.id)" v-else-if="index == 0 && idx != 0 && item.lists[idx - 1].status == '已学完'" class="clearfix item">
								<span class="title fl">{{items.title}} （{{items.keshi}}学时）---- {{items.teacher}}</span>
								<div class="jindu fr">{{items.jindu}}</div>
								<div class="fr btn" v-if="items.status == '未学习'" :class="['btn2']">未学习</div>
								<div class="fr btn" v-if="items.status == '学习中'" :class="['btn1']">学习中</div>
								<div class="fr btn" v-if="items.status == '已学完'">已学完</div>
							</div>
							<div @click="gotoVideo(items.id)" v-else-if="index != 0 && idx == 0 && courseChapterInfo.chapter[index - 1].lists[courseChapterInfo.chapter[index - 1].lists.length - 1].status == '已学完'" class="clearfix item">
								<span class="title fl">{{items.title}} （{{items.keshi}}学时）---- {{items.teacher}}</span>
								<div class="jindu fr">{{items.jindu}}</div>
								<div class="fr btn" v-if="items.status == '未学习'" :class="['btn2']">未学习</div>
								<div class="fr btn" v-if="items.status == '学习中'" :class="['btn1']">学习中</div>
								<div class="fr btn" v-if="items.status == '已学完'">已学完</div>
							</div>
							<div @click="gotoVideo(items.id)" v-else-if="index != 0 && idx != 0 && item.lists[idx - 1].status == '已学完'" class="clearfix item">
								<span class="title fl">{{items.title}} （{{items.keshi}}学时）---- {{items.teacher}}</span>
								<div class="jindu fr">{{items.jindu}}</div>
								<div class="fr btn" v-if="items.status == '未学习'" :class="['btn2']">未学习</div>
								<div class="fr btn" v-if="items.status == '学习中'" :class="['btn1']">学习中</div>
								<div class="fr btn" v-if="items.status == '已学完'">已学完</div>
							</div>
							<li v-else @click="$toast.error('请先学习完上一节再观看');" class="clearfix item">
								<span class="title fl">{{items.title}} （{{items.keshi}}学时）---- {{items.teacher}}</span>
								<div class="jindu fr">{{items.jindu}}</div>
								<div class="fr btn" v-if="items.status == '未学习'" :class="['btn2']">未学习</div>
								<div class="fr btn" v-if="items.status == '学习中'" :class="['btn1']">学习中</div>
								<div class="fr btn" v-if="items.status == '已学完'">已学完</div>
							</li>
						</template>
						
						
						<!-- 
						<router-link @click="gotoVideo(item.)" class="title fl" v-else-if="index != 0 && idx == 0 && courseChapterInfo.chapter[index - 1].lists[courseChapterInfo.chapter[index - 1].lists.length - 1].status == '已学完'">{{items.title}} （{{items.keshi}}学时）---- {{items.teacher}}</router-link>
						<router-link :to="'/learnCenter/video?classes_id=' + courseChapterInfo.classes_id + '&course_id=' + items.id + '&student_id=' + $route.query.id" class="title fl" v-else-if="index != 0 && idx != 0 && item.lists[idx - 1].status == '已学完'">{{items.title}} （{{items.keshi}}学时）---- {{items.teacher}}</router-link>
						<span class="title fl" v-else @click="$toast.error('请先学习完上一节再观看');">{{items.title}} （{{items.keshi}}学时）---- {{items.teacher}}</span> -->
					</ul>
				</li>
			</ul>
		</div>
		
		<!-- 公共底部 -->
		<!-- <Footer></Footer> -->
	</div>
</template>

<script>
	import Header from '@/components/Public/Header.vue';
	import Footer from '@/components/Public/Footer.vue';
	
	export default {
		components: {
			Header,
			Footer
		},
		 
		data() {
			return {
				courseChapterInfo: '',
				classid:'',
			student_id:''
			}
		},
		computed: {
			uid() {
				return this.$store.state.uid;
			}
		},
		activated(){
			this.getUserInfo();
		},
		deactivated() {
			this.courseChapterInfo = '';
		},
		methods: {

			gotoVideo(e){
					let uid = this.uid;
					let classid = this.classid;
					let student_id = this.student_id;
					let course_id = e;
				let otime =localStorage.getItem('overTime')
					 wx.miniProgram.getEnv(function(res){
      				  if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/learnCenters/Video?uid='+uid+'&classid='+classid+'&course_id='+course_id+'&student_id='+student_id+'&otime='+otime});
               			}else{
						
						}
      				  });
			},
			// 获取项目列表
			async getList() {
				var uid = this.uid;
				var student_id = this.$route.query.id;
				var data = {
					uid, student_id
				}
				this.$toast.loading('加载中...');
				const { data:res } = await this.$http.get('/study/courseChapter', { params: data });
				this.$toast.close();
				if(this.$store.state.beta){
					console.log('学习中心 视频章节列表：' + new Date().getHours() + ':' + new Date().getMinutes() + ':' + new Date().getSeconds() , res);
				}
				var code = res.code;
				if(code == 1) {
					var courseChapterInfo = res.data;
					this.student_id = res.data.student_id;
					this.classid = res.data.classes_id;
					for(var i = 0; i < courseChapterInfo.chapter.length; i++) {
						courseChapterInfo.chapter[i]['openStatus'] = false;
					}
					this.courseChapterInfo = courseChapterInfo;
				} else {
					this.courseChapterInfo = '';
				}
			},
			// 获取用户信息
			async getUserInfo(){
				if(!this.uid){
					const confirmResult = await this.$messageBox.confirm('您当前未登录，请登录后再进行操作！', '登录提醒').catch(error => error);
					if(confirmResult != 'confirm'){
								wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
						return;
					}
							wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
				} else {
					this.getList();
				}
			},
		},
	};
</script>

<style scoped>
	.dataUpload-box{
		margin-top: 0.3rem;
		margin-bottom: 0.8rem;
		box-shadow: 0 0 0.1rem rgba(0,0,0,.2);
	}
	.dataUpload-box .yiji{
		
	}
	.dataUpload-box .yiji>li{
		
	}
	.dataUpload-box .yiji>li .yiji-box{
		background: #f6f6f6;
		padding: 0.3rem 0 0.3rem 0.6rem;
		position: relative;
		cursor: pointer;
	}
	.dataUpload-box .yiji>li .yiji-box .icon{
		position: absolute;
		width: 0.16rem;
		height: 0.09rem;
		left: 0.24rem;
		top: 50%;
		transform: translate(0,-50%) rotate(-90deg);
		-ms-transform: translate(0,-50%) rotate(-90deg);
		-o-transform: translate(0,-50%) rotate(-90deg);
		-moz-transform: translate(0,-50%) rotate(-90deg);
		-webkit-transform: translate(0,-50%) rotate(-90deg);
		transition: all .3s;
	}
	.dataUpload-box .yiji>li .yiji-box.active .icon{
		transform: translate(0,-50%) rotate(0);
		-ms-transform: translate(0,-50%) rotate(0);
		-o-transform: translate(0,-50%) rotate(0);
		-moz-transform: translate(0,-50%) rotate(0);
		-webkit-transform: translate(0,-50%) rotate(0);
	}
	.dataUpload-box .yiji>li .yiji-box .title{
		font-size: 0.28rem;
		color: #333333;
		line-height: 0.4rem;
	}
	.dataUpload-box .yiji>li .erji{
		background: #ffffff;
		height: 0;
		overflow: hidden;
		transition: all 1s;
	}
	.dataUpload-box .yiji>li .erji.active{
		height: auto;
	}
	.dataUpload-box .yiji>li .erji .item{
		padding: 0.18rem 0.3rem 0.18rem 0.6rem;
		border-bottom: 0.02rem solid #e7e7e7;
		display: block;
	}
	.dataUpload-box .yiji>li .erji .item .title{
		font-size: 0.26rem;
		color: #333333;
		height: 0.44rem;
		line-height: 0.44rem;
		max-width: 4.8rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.dataUpload-box .yiji>li .erji .item .jindu{
		width: 0.8rem;
		text-align: right;
		font-size: 0.24rem;
		color: #333333;
		height: 0.44rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.dataUpload-box .yiji>li .erji .item .btn{
		font-size: 0.24rem;
		color: #1fbb00;
		background: #ffffff;
		border: 0.02rem solid #1fbb00;
		border-radius: 0.04rem;
		padding: 0.04rem 0.08rem;
	}
	.dataUpload-box .yiji>li .erji .item .btn1{
		color: #ff6000;
		border: 0.02rem solid #ff6000;
	}
	.dataUpload-box .yiji>li .erji .item .btn2{
		color: #c7c7c7;
		border: 0.02rem solid #c7c7c7;
	}
</style>